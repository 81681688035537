import Head from 'next/head'
import Layout from "@/components/layout"
import Link from 'next/link'
import Image from 'next/image'
export default function PageNotFound() {
  return (
    <Layout>
      <Head>
        <title>404 - Page not found</title>
      </Head>
      <div className="isolate bg-white">
        <main>
          <div className="relative px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-12 sm:py-16">
              <div className="text-center">
                <Image className="max-w-xs mx-auto my-10" height="260" width="260" src="/images/404.svg" alt="error 404" />
                <h1 className="mb-12 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Page not found
                </h1>
                <Link className='text-gray-500 hover:underline' href='/'>Back to home</Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}