import { useSession } from "next-auth/react"
import React from 'react'  
import Loading from "./loading-full";

 
  export default function Layout({ children }: { children: React.ReactNode }) {
     
    const { status } = useSession()

    if (status === 'loading') {
        return <Loading />
    }

    return <>{ children }</>;
}